<template>
  <v-container id="forgot" fluid tag="section">
    <v-toolbar color="#1B5E20">
      <v-btn
        icon
        dark
        @click.native="close"
        color="white"
        elevation="0"
        class="white--text"
        large
      >
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="white--text">Forgot Password</v-toolbar-title>
    </v-toolbar>
    <v-row align="center" justify="center">
      <v-overlay color="White" :absolute="absolute" :value="overlay">
        <div class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-overlay>
    </v-row>
    <v-divider></v-divider>
    <v-container fluid>
      <v-form ref="form" v-model="valid" lazy-validation aria-autocomplete="off">
        <v-text-field
          v-model="email"
          type="email"
          id="email"
          :rules="emailRules"
          label="E-mail"
          required
          append-icon="mdi-email"
        ></v-text-field>

        <div class="text-xs-center">
          <v-btn class="white--text" color="#1B5E20" @click="requestResetPassword">
            Send Password Reset Link
            <v-icon right dark>mdi-send</v-icon>
          </v-btn>
        </div>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      email: "",
      absolute: true,
      overlay: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      prevUrl: "prevUrl", // <= ini
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    requestResetPassword() {
      this.overlay = true;
      let formData = {
        email: this.email,
      };
      this.axios
        .post("/reset-password", formData)
        .then((response) => {
          let { data } = response;
          this.overlay = false;
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
        })
        .catch((error) => {
          let { data } = error;
          this.overlay = false;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
        });
    },
    close() {
      return this.$router.push({ path: "/login" });
    },
  },
};
</script>
